import { useState } from "react";
import { Container, Grid } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Footer from "./static/components/Footer";
import SocialLinks from "./static/components/SocialLinks";
import Showcase from "./static/components/Showcase";
import Biography from "./static/components/Biography";
import Medium from "./static/components/Medium";
import ProjectPage from "./components/ProjectPage";
import ProjectsPage from "./components/ProjectsPage";
import "./App.css";

const MainContent = () => (
	<Container sx={{ marginTop: 2 }}>
		<Grid container spacing={5} direction="row" justifyContent="center">
			<Grid item xs={12} md={7}>
				<Biography />
			</Grid>
			<Grid item xs={12} md={5}>
				<Showcase />
			</Grid>

			{/* 
			<Grid item xs={12} md={12}>
				<Medium />
			</Grid>
			*/}
			<Grid item xs={12} md={12}>
				<SocialLinks />
			</Grid>
		</Grid>
	</Container>
);

export default function App() {
	const [toggleDarkMode, setToggleDarkMode] = useState(true);

	const toggleDarkTheme = () => {
		setToggleDarkMode(!toggleDarkMode);
	};

	const theme = createTheme({
		palette: {
			mode: toggleDarkMode ? "dark" : "light",
			primary: {
				main: "#4caf50",
			},
			secondary: {
				main: "#8bc34a",
			},
		},
	});

	document.body.className = toggleDarkMode ? "dark" : "light";

	return (
		<Router>
			<ThemeProvider theme={theme}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						transition: "color 0.5s",
						alignItems: "center",
					}}
				>
					<IconButton
						onClick={toggleDarkTheme}
						size="large"
						sx={{
							marginTop: 1,
							"&:hover": {
								backgroundColor: "transparent",
								boxShadow: "none",
							},
							animation: toggleDarkMode
								? "iconChange 0.5s"
								: "none",
						}}
					>
						{toggleDarkMode ? <WbSunnyIcon /> : <Brightness3Icon />}
					</IconButton>
				</div>

				<CssBaseline />

				<Routes>
					<Route path="/" element={<MainContent />} />
					<Route path="/projects" element={<ProjectsPage />} />
					<Route path="/:projectId" element={<ProjectPage />} />
				</Routes>

				<Footer />
			</ThemeProvider>
		</Router>
	);
}
