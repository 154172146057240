import React from "react";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";

// Social Assets
import WorkIcon from "@mui/icons-material/Work";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const SocialLinks = () => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Link
				href="https://www.upwork.com/freelancers/furkansuren"
				rel="noopener"
				underline="none"
			>
				<IconButton
					size="large"
					sx={{
						"&:hover": {
							backgroundColor: "transparent",
							boxShadow: "none",
							transform: "scale(1.1)",
							transition: "transform 0.2s ease-in-out",
						},
					}}
				>
					<WorkIcon fontSize="inherit" />
				</IconButton>
			</Link>
			<Link
				href="https://github.com/surkanfuren"
				rel="noopener"
				underline="none"
			>
				<IconButton
					size="large"
					sx={{
						"&:hover": {
							backgroundColor: "transparent",
							boxShadow: "none",
							transform: "scale(1.1)",
							transition: "transform 0.2s ease-in-out",
						},
					}}
				>
					<GitHubIcon fontSize="inherit" />
				</IconButton>
			</Link>
			{/*
            <Link href="https://www.youtube.com/@furcoding" rel="noopener" underline="none">
              <IconButton size="large" sx={{ '&:hover': {backgroundColor: 'transparent', boxShadow: 'none', transform: 'scale(1.1)', transition: 'transform 0.2s ease-in-out',} }}>
                <YouTubeIcon fontSize="inherit" />
              </IconButton>
            </Link>
            */}
			<Link
				href="https://twitter.com/surkanfuren"
				rel="noopener"
				underline="none"
			>
				<IconButton
					size="large"
					sx={{
						"&:hover": {
							backgroundColor: "transparent",
							boxShadow: "none",
							transform: "scale(1.1)",
							transition: "transform 0.2s ease-in-out",
						},
					}}
				>
					<TwitterIcon fontSize="inherit" />
				</IconButton>
			</Link>

			{/*
			<Link
				href="https://www.instagram.com/surkanfuren/"
				rel="noopener"
				underline="none"
			>
				<IconButton
					size="large"
					sx={{
						"&:hover": {
							backgroundColor: "transparent",
							boxShadow: "none",
							transform: "scale(1.1)",
							transition: "transform 0.2s ease-in-out",
						},
					}}
				>
					<InstagramIcon fontSize="inherit" />
				</IconButton>
			</Link>*/}
		</div>
	);
};

export default SocialLinks;
