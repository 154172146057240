export const projects = [
	{
		id: "dan",
		logo: "https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994059/nxbwheeddsim22gfuvnw.png",
		name: "Dan AI",
		description: "Advanced voice mode component",
		link: "https://apps.apple.com/us/app/dan-ai-voice-chat-companion/id6502860590",
		screenshots: [
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994202/ageltmewoctaoys6mw3o.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994202/xvqnvvydkuvgwpkxjcd3.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994201/osc41lhyqppuax8wijdj.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994201/iay9am0ztypmq8gtnegn.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994202/cjrmdrfhs7okanamgoav.png",
		],
	},
	{
		id: "orbit",
		logo: "https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994060/rdjyqjhqbhncnhcuqnzd.png",
		name: "Orbit AI",
		description: "Powered by GPT-o1 & OpenAI API",
		link: "https://apps.apple.com/us/app/orbit-gpt-o1-chat-assistant/id6612007544",
		screenshots: [
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994345/ajwf9kmmweu0whc1yvzy.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994345/qnmhfxahl47p2fzyfvll.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994346/o4zidwjpe3ay9zs75dyk.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994346/cxqqbgsaufll1ngeobai.png",
		],
	},
	{
		id: "mathbot",
		logo: "https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994059/qitfegd5f53kf53ysho0.png",
		name: "AI Math Solver",
		description: "Problem Solver & Homework Help",
		link: "https://apps.apple.com/us/app/ai-math-calculator-mathbot/id6682705979",
		screenshots: [
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994215/eojazlu66y9yoslhcngp.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994215/hvmy0i8cflustohjb1qj.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994215/k5tbpeadr4auuipll4gr.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994216/tt0y6anl74qau77huffg.png",
		],
	},
	{
		id: "findart",
		logo: "https://res.cloudinary.com/dmfl9kvqy/image/upload/v1730205498/icon_y8hyem.png",
		name: "AI Artwork Finder: FindArt",
		description: "Discover Art Secrets & Stories",
		link: "https://apps.apple.com/us/app/artwork-finder-scan-paintings/id6708240673",
		screenshots: [
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1730468270/01_pn5287.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1730468270/02_rvueeu.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1730468269/03_wir3nm.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1730468269/04_b1tahc.png",
		],
	},
	{
		id: "stone-identifier",
		logo: "https://res.cloudinary.com/dmfl9kvqy/image/upload/v1730206438/icon_ijsezb.png",
		name: "Stone Identifier: Rock Analyse",
		description: "Identify crystal mineral shard",
		link: "https://apps.apple.com/us/app/stone-identifier-rock-analyse/id6736365953",
		screenshots: [
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1730206253/01_akbcuz.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1730206253/02_zuuskf.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1730206253/03_iiry2f.png",
			"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1730206253/04_rbvdzd.png",
		],
	},
];
