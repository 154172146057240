import React from "react";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";

const Footer = () => {
  return (
    <Container>
      <footer
        style={{
          marginTop: "20px",
          textAlign: "center",
          marginBottom: "50px",
          alignItems: "center",
        }}
      >
        <hr />
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: "15px" }}
        >
          © {new Date().getFullYear()} · Aydin Furkan Suren
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: "5px", marginLeft: "auto" }}
        >
          contact@furkansuren.com
        </Typography>
      </footer>
    </Container>
  );
};

export default Footer;
