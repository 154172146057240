import React, { useState } from "react";
import {
	Container,
	Typography,
	Grid,
	Card,
	CardContent,
	CardMedia,
	CardActionArea,
	Box,
	Button,
	useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { projects } from "../data/projects";
import LaunchIcon from "@mui/icons-material/Launch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Pagination from "@mui/material/Pagination";
import useMediaQuery from "@mui/material/useMediaQuery";

const ProjectsPage = () => {
	const [hoveredProject, setHoveredProject] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
	const projectsPerPage = isDesktop ? 6 : 4;
	const indexOfLastProject = currentPage * projectsPerPage;
	const indexOfFirstProject = indexOfLastProject - projectsPerPage;
	const currentProjects = projects.slice(
		indexOfFirstProject,
		indexOfLastProject
	);
	const navigate = useNavigate();
	const theme = useTheme();

	return (
		<Container maxWidth="lg">
			<Button
				startIcon={<ArrowBackIcon />}
				onClick={() => navigate("/")}
				sx={{
					position: "absolute",
					left: theme.spacing(3),
					top: theme.spacing(3),
					color: theme.palette.text.primary,
					"&:hover": {
						backgroundColor: theme.palette.action.hover,
					},
				}}
			>
				Back
			</Button>
			<Typography
				variant="h2"
				component="h1"
				gutterBottom
				align="center"
				sx={{ mb: 6, fontWeight: "bold" }}
			>
				<Box
					component="span"
					sx={{
						background:
							"linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
						backgroundClip: "text",
						textFillColor: "transparent",
					}}
				>
					Mobile AI Projects
				</Box>
			</Typography>
			<Grid container spacing={4}>
				{currentProjects.map((project) => (
					<Grid item xs={6} sm={6} md={4} key={project.id}>
						<motion.div
							whileHover={{ scale: 1.05 }}
							whileTap={{ scale: 0.95 }}
							onHoverStart={() => setHoveredProject(project.id)}
							onHoverEnd={() => setHoveredProject(null)}
						>
							<Card
								elevation={
									hoveredProject === project.id ? 8 : 2
								}
								sx={{
									height: "100%",
									display: "flex",
									flexDirection: "column",
									background: theme.palette.background.paper,
									borderRadius: "16px",
									overflow: "hidden",
								}}
							>
								<CardActionArea
									component={Link}
									to={`/${project.id}`}
								>
									<CardMedia
										component="img"
										height="200"
										image={project.logo}
										alt={project.name}
										sx={{ objectFit: "cover", p: 2 }}
									/>
									<CardContent sx={{ flexGrow: 1 }}>
										<Typography
											variant="h5"
											component="h2"
											gutterBottom
											sx={{ fontWeight: "bold" }}
										>
											{project.name}
										</Typography>
										<Typography
											variant="body2"
											color="text.secondary"
											paragraph
										>
											{project.description}
										</Typography>
									</CardContent>
								</CardActionArea>

								<Button
									variant="contained"
									endIcon={<LaunchIcon />}
									href={project.link}
									target="_blank"
									rel="noopener noreferrer"
									fullWidth
									sx={{
										background:
											"linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
										color: "white",
										boxShadow:
											"0 3px 5px 2px rgba(33, 203, 243, .3)",
									}}
								>
									View App
								</Button>
							</Card>
						</motion.div>
					</Grid>
				))}
			</Grid>
			<Pagination
				count={Math.ceil(projects.length / projectsPerPage)}
				page={currentPage}
				onChange={(event, value) => setCurrentPage(value)}
				sx={{ mt: 4, display: "flex", justifyContent: "center" }}
			/>
		</Container>
	);
};

export default ProjectsPage;
