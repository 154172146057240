import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

// Import projects data
import { projects } from "../../data/projects";

const Showcase = () => {
	const [currentPage, setCurrentPage] = useState(0);
	const projectsPerPage = 4;
	const itemHeight = 230; // Set the height for each item
	const listHeight = itemHeight * 2; // Total height for 4 items

	const handleNextPage = () => {
		if ((currentPage + 1) * projectsPerPage < projects.length) {
			setCurrentPage(currentPage + 1);
		}
	};

	const handlePrevPage = () => {
		if (currentPage > 0) {
			setCurrentPage(currentPage - 1);
		}
	};

	return (
		<div>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					marginBottom: 2,
				}}
			>
				<Typography
					variant="h4"
					component="div"
					sx={{
						animation: "changeColor 5s infinite",
						"@keyframes changeColor": {
							"0%": { color: "#8e44ad" },
							"25%": { color: "#3498db" },
							"50%": { color: "#2980b9" },
							"75%": { color: "#1abc9c" },
							"100%": { color: "#8e44ad" },
						},
					}}
				>
					My Projects 👨🏻‍💻
				</Typography>
				<Button
					component={Link}
					to="/projects"
					variant="outlined"
					color="inherit"
					size="small"
				>
					View All
				</Button>
			</Box>

			<ImageList
				sx={{
					width: "100%",
					height: listHeight,
					marginTop: "25px",
					margin: "10px auto 0",
					overflow: "hidden",
				}}
			>
				{projects
					.slice(
						currentPage * projectsPerPage,
						(currentPage + 1) * projectsPerPage
					)
					.map((project, index) => (
						<Link
							to={`/${project.id}`}
							key={project.id}
							style={{ textDecoration: "none" }}
						>
							<Grow in={true} timeout={(index + 1) * 500}>
								<ImageListItem>
									<img
										style={{ pointerEvents: "none" }}
										src={project.logo}
										alt={project.name}
										loading="lazy"
									/>
									<ImageListItemBar
										sx={{ width: "100%", height: "20%" }}
										title={project.name}
										subtitle={project.description}
									/>
								</ImageListItem>
							</Grow>
						</Link>
					))}
			</ImageList>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					marginTop: 2,
				}}
			>
				<Button
					sx={{
						background:
							"linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
						color: "white",
					}}
					onClick={handlePrevPage}
					disabled={currentPage === 0}
				>
					Previous
				</Button>
				<Button
					sx={{
						background:
							"linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
						color: "white",
					}}
					onClick={handleNextPage}
					disabled={
						(currentPage + 1) * projectsPerPage >= projects.length
					}
				>
					Next
				</Button>
			</Box>
		</div>
	);
};

export default Showcase;
