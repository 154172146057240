import React from "react";
import Link from "@mui/material/Link";
import {
	Card,
	CardContent,
	CardMedia,
	Typography,
	useTheme,
	useMediaQuery,
	Box,
	Container,
	Paper,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
// Change?
const MobileTransition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="right" ref={ref} {...props} />;
});

const Biography = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const currentYear = new Date().getFullYear();
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// Add this constant at the top of your component
	const cloudinaryUrl =
		"https://res.cloudinary.com/dmfl9kvqy/image/upload/v1728994167/peo0liprtkohrxrhouln.jpg";

	return (
		<Container maxWidth="md">
			<Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
				<Box
					display="flex"
					flexDirection={isMobile ? "column" : "row"}
					alignItems="center"
				>
					<CardMedia
						sx={{
							width: isMobile ? 150 : 200,
							height: isMobile ? 150 : 200,
							borderRadius: "50%",
							marginRight: isMobile ? 0 : 3,
							marginBottom: isMobile ? 2 : 0,
						}}
						component="img"
						image={cloudinaryUrl}
						style={{ pointerEvents: "none" }}
					/>
					<Box>
						<Typography variant="h4" component="h1" gutterBottom>
							Furkan Suren
						</Typography>
						<Typography
							variant="h6"
							color="text.secondary"
							gutterBottom
						>
							Full-Stack Developer & AI Enthusiast
						</Typography>
						<Typography variant="body1" paragraph>
							From{" "}
							<Link
								href="https://www.google.com/maps/@38.4002554,26.9213062,9.93z"
								target="_blank"
								rel="noopener"
								color="inherit"
							>
								Izmir, Turkey 🇹🇷
							</Link>
						</Typography>
						<Typography variant="body1" paragraph>
							I'm passionate about building AI-integrated projects
							& working with real-world data.
						</Typography>
						<Typography
							onClick={handleClickOpen}
							sx={{
								"&:hover": {
									cursor: "pointer",
									textDecoration: "underline",
								},
								color: theme.palette.info.main,
							}}
						>
							Read more about me
						</Typography>
					</Box>
				</Box>
			</Paper>

			{isMobile && (
				<Dialog
					open={open}
					TransitionComponent={MobileTransition}
					keepMounted
					onClose={handleClose}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle
						sx={{ m: 0, p: 2 }}
						id="customized-dialog-title"
					>
						About
					</DialogTitle>
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							"&:hover": {
								backgroundColor: "transparent",
								boxShadow: "none",
								transform: "scale(1.1)",
								transition: "transform 0.2s ease-in-out",
							},
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
					<DialogContent dividers>
						<DialogContentText id="alert-dialog-slide-description">
							I'm a {currentYear - 2002}-year-old developer.
							<br />
							<br />
							Currently building AI powered mobile apps in
							entertainment & productivity categories.
							<br />
							<br />I was freelancing earlier on{" "}
							<Link
								style={{ color: "#14A800" }}
								href="https://www.upwork.com/freelancers/furkansuren"
								underline="none"
								target="_blank"
							>
								Upwork
							</Link>{" "}
							as a full stack developer while also studying
							Machine Learning.
							<br />
							<br />
							I'm trained in cloud computing and most of the time
							building on cloud infrastructure. Since I've spent
							more than 3 years as a freelancer, I've seen many
							development scenarios and workflows.
							<br />
							<br />
							These scenaries have taught me to identify different
							solutions for each case during deployment stage.
						</DialogContentText>
					</DialogContent>
				</Dialog>
			)}
			{!isMobile && (
				<Dialog
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleClose}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle
						sx={{ m: 0, p: 2 }}
						id="customized-dialog-title"
					>
						About
					</DialogTitle>
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							"&:hover": {
								backgroundColor: "transparent",
								boxShadow: "none",
								transform: "scale(1.1)",
								transition: "transform 0.2s ease-in-out",
							},
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
					<DialogContent dividers>
						<DialogContentText id="alert-dialog-slide-description">
							I'm a {currentYear - 2002}-year-old developer.
							<br />
							<br />
							Currently building AI powered mobile apps in
							entertainment & productivity categories.
							<br />
							<br />I was freelancing earlier on{" "}
							<Link
								style={{ color: "#14A800" }}
								href="https://www.upwork.com/freelancers/furkansuren"
								underline="none"
								target="_blank"
							>
								Upwork
							</Link>{" "}
							as a full stack developer while also studying
							Machine Learning.
							<br />
							<br />
							I'm trained in cloud computing and most of the time
							building on cloud infrastructure. Since I've spent
							more than 3 years as a freelancer, I've seen many
							development scenarios and workflows.
							<br />
							<br />
							These scenaries have taught me to identify different
							solutions for each case during deployment stage.
						</DialogContentText>
					</DialogContent>
				</Dialog>
			)}
		</Container>
	);
};

export default Biography;
