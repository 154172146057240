import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
	Container,
	Typography,
	Box,
	Link,
	useTheme,
	Button,
	Modal,
	Fade,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { projects } from "../data/projects";
import appStoreBadgeBlack from "../static/Download_on_App_Store/Black_lockup/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import appStoreBadgeWhite from "../static/Download_on_App_Store/White_lockup/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProjectPage = () => {
	const { projectId } = useParams();
	const navigate = useNavigate();
	const project = projects.find((p) => p.id === projectId);
	const theme = useTheme();
	const [openModal, setOpenModal] = useState(false);
	const [selectedImage, setSelectedImage] = useState("");

	if (!project) {
		return (
			<Container>
				<Typography variant="h4">Project not found</Typography>
			</Container>
		);
	}

	const appStoreImage =
		theme.palette.mode === "dark" ? appStoreBadgeBlack : appStoreBadgeWhite;

	const sliderSettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					arrows: false, // Hide arrows on mobile
				},
			},
		],
		lazyLoad: "ondemand",
	};

	const handleImageClick = (image) => {
		setSelectedImage(image);
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter" || event.key === "Escape") {
			handleCloseModal();
		}
	};

	return (
		<Container maxWidth="md" sx={{ marginTop: 4, marginBottom: 1 }}>
			<Button
				startIcon={<ArrowBackIcon />}
				onClick={() => navigate("/projects")}
				sx={{
					position: "absolute",
					left: theme.spacing(3),
					top: theme.spacing(3),
					color: theme.palette.text.primary,
					"&:hover": {
						backgroundColor: theme.palette.action.hover,
					},
				}}
			>
				Back
			</Button>

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					textAlign: "center",
					marginBottom: 0,
				}}
			>
				<Box
					sx={{
						width: "120px",
						height: "120px",
						borderRadius: "24px",
						overflow: "hidden",
						boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
						marginBottom: 2,
					}}
				>
					<img
						src={project.logo}
						alt={project.name}
						style={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
						}}
					/>
				</Box>
				<Typography variant="h2" component="h1" fontWeight="bold">
					{project.name}
				</Typography>
			</Box>
			<Typography
				variant="body1"
				paragraph
				sx={{
					fontSize: "1.1rem",
					maxWidth: "700px",
					margin: "0 auto 2rem",
					textAlign: "center",
				}}
			>
				{project.description}
			</Typography>
			<Box sx={{ marginTop: 1, marginBottom: 1, overflow: "hidden" }}>
				<Slider {...sliderSettings}>
					{project.screenshots.map((screenshot, index) => (
						<Box key={index} sx={{ padding: "0 5px" }}>
							<LazyLoadImage
								src={screenshot}
								alt={`Screenshot ${index + 1}`}
								effect="blur"
								placeholderSrc={`${screenshot}?w=10&q=10`}
								threshold={300}
								style={{
									width: "100%",
									height: "auto",
									borderRadius: "8px",
									boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
									cursor: "pointer",
								}}
								onClick={() => handleImageClick(screenshot)}
							/>
						</Box>
					))}
				</Slider>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					marginTop: 4,
				}}
			>
				<Link
					href={project.link}
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						src={appStoreImage}
						alt="Download on App Store"
						style={{ height: "75px" }}
					/>
				</Link>
			</Box>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				closeAfterTransition
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: "rgba(0, 0, 0, 0.8)",
				}}
			>
				<Fade in={openModal}>
					<img
						src={selectedImage}
						alt="Full-screen project screenshot"
						style={{
							maxWidth: "90%",
							maxHeight: "90%",
							objectFit: "contain",
							boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
							borderRadius: "8px",
							outline: "none",
							cursor: "pointer",
							tabIndex: 0,
						}}
						onClick={handleCloseModal}
						onKeyDown={handleKeyDown}
					/>
				</Fade>
			</Modal>
		</Container>
	);
};

export default ProjectPage;
